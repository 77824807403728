export const WtRequestsMap = {
  GET_ALL_WT_REQUESTS_START: "GET_ALL_WT_REQUESTS_START",
  GET_ALL_WT_REQUESTS_SUCCESS: "GET_ALL_WT_REQUESTS_SUCCESS",
  GET_ALL_WT_REQUESTS_ERROR: "GET_ALL_WT_REQUESTS_ERROR",
  COUNTRY_FILTERS: "COUNTRY_FILTERS",
  APPLY_FILTERS: "APPLY_FILTERS",
  SET_SORT_CHANGE: "SET_SORT_CHANGE",
  SEARCH_TEXT_CHANGE: "SEARCH_TEXT_CHANGE",
  SEARCH_STATUS_CHANGE: "SEARCH_STATUS_CHANGE",
  REFRESH_WT_REQUESTS_LIST: "REFRESH_WT_REQUESTS_LIST",
  SET_REQUESTS_BATCH_NUMBER: "SET_REQUESTS_BATCH_NUMBER",
  APPROVE_REQUEST_START: "APPROVE_REQUEST_START",
  APPROVE_REQUEST_SUCCESS: "APPROVE_REQUEST_SUCCESS",
  APPROVE_REQUEST_ERROR: "APPROVE_REQUEST_ERROR",
  APPROVE_PROJECT_START: "APPROVE_PROJECT_START",
  APPROVE_PROJECT_SUCCESS: "APPROVE_PROJECT_SUCCESS",
  APPROVE_PROJECT_ERROR: "APPROVE_PROJECT_ERROR",
  DECLINE_PROJECT_START: "DECLINE_PROJECT_START",
  DECLINE_PROJECT_SUCCESS: "DECLINE_PROJECT_SUCCESS",
  DECLINE_PROJECT_ERROR: "DECLINE_PROJECT_ERROR"
};

export const WtRequestsActions = {
  getAllWtRequestsStart: (data) => ({
    type: WtRequestsMap.GET_ALL_WT_REQUESTS_START,
    payload: data,
  }),
  getAllWtRequestsSuccess: (data) => ({
    type: WtRequestsMap.GET_ALL_WT_REQUESTS_SUCCESS,
    payload: data,
  }),
  getAllWtRequestsError: (errors) => ({
    type: WtRequestsMap.GET_ALL_WT_REQUESTS_ERROR,
    payload: { errors },
  }),
  applyFilters: (data) => ({
    type: WtRequestsMap.APPLY_FILTERS,
    payload: data,
  }),
  refreshRequestList: () => ({
    type: WtRequestsMap.REFRESH_WT_REQUESTS_LIST,
  }),
  searchTextChange: (data) => ({
    type: WtRequestsMap.SEARCH_TEXT_CHANGE,
    payload: data,
  }),
  searchStatusChange: (data) => ({
    type: WtRequestsMap.SEARCH_STATUS_CHANGE,
    payload: data,
  }),
  setSort: (data) => ({
    type: WtRequestsMap.SET_SORT_CHANGE,
    payload: data,
  }),
  setWtRequestsBatchNumber: (data) => ({
    type: WtRequestsMap.SET_REQUESTS_BATCH_NUMBER,
    payload: data,
  }),
  approveRequestStart: (data) => ({
    type: WtRequestsMap.APPROVE_REQUEST_START,
    payload: data,
  }),
  approveRequestSuccess: (data) => ({
    type: WtRequestsMap.APPROVE_REQUEST_SUCCESS,
    payload: data,
  }),
  approveRequestError: (errors) => ({
    type: WtRequestsMap.APPROVE_REQUEST_ERROR,
    payload: { errors },
  }),
  approveProjectStart: (data) => ({
    type: WtRequestsMap.APPROVE_PROJECT_START,
    payload: data,
  }),
  approveProjectSuccess: (data) => ({
    type: WtRequestsMap.APPROVE_PROJECT_SUCCESS,
    payload: data,
  }),
  approveProjectError: (errors) => ({
    type: WtRequestsMap.APPROVE_PROJECT_ERROR,
    payload: { errors },
  }),
  declineProjectStart: (data) => ({
    type: WtRequestsMap.DECLINE_PROJECT_START,
    payload: data,
  }),
  declineProjectSuccess: (data) => ({
    type: WtRequestsMap.DECLINE_PROJECT_SUCCESS,
    payload: data,
  }),
  declineProjectError: (errors) => ({
    type: WtRequestsMap.DECLINE_PROJECT_ERROR,
    payload: { errors },
  }),
};
