import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
//import renderHTML from 'react-render-html';
//import parser, { attributesToProps } from "html-react-parser";
// NOTE: Use the editor from source (not a build)!
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import SourceEditing from "@ckeditor/ckeditor5-source-editing/src/sourceediting";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import Link from "@ckeditor/ckeditor5-link/src/link";
import List from "@ckeditor/ckeditor5-list/src/list";
import BlockQuote from "@ckeditor/ckeditor5-block-quote/src/blockquote";
import Table from "@ckeditor/ckeditor5-table/src/table";
import TableToolbar from "@ckeditor/ckeditor5-table/src/tabletoolbar";
import GeneralHtmlSupport from "@ckeditor/ckeditor5-html-support/src/generalhtmlsupport";
import Image from "@ckeditor/ckeditor5-image/src/image";
import ImageCaption from "@ckeditor/ckeditor5-image/src/imagecaption";
import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle";
import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar";
import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload";
import axios from "axios";
import {
  ImageInsert,
  ImageResizeEditing,
  ImageResizeHandles,
} from "@ckeditor/ckeditor5-image";
import { MediaEmbed } from "@ckeditor/ckeditor5-media-embed";
import Alignment from "@ckeditor/ckeditor5-alignment/src/alignment";

const awsAxios = axios.create();

const editorConfiguration = {
  plugins: [
    Bold,
    Italic,
    Paragraph,
    SourceEditing,
    Heading,
    Link,
    List,
    Table,
    TableToolbar,
    GeneralHtmlSupport,
    Image,
    ImageCaption,
    ImageResizeEditing,
    ImageResizeHandles,
    ImageStyle,
    ImageToolbar,
    ImageUpload,
    BlockQuote,
    Essentials,
    ImageInsert,
    MediaEmbed,
    Alignment,
  ],
  toolbar: {
    items: [
      "heading",
      "|",
      "bold",
      "italic",
      "underline",
      "|",
      "alignment",
      "|",
      "numberedList",
      "bulletedList",
      "blockQuote",
      "|",
      "link",
      "insertImage",
      "insertTable",
      "mediaEmbed",
      "|",
      "undo",
      "redo",
    ],
  },
  language: "en",
  mediaEmbed: {
    previewsInData: true,
  },
  image: {
    toolbar: [
      "imageTextAlternative",
      "toggleImageCaption",
      "imageStyle:inline",
      "imageStyle:block",
      "imageStyle:side",
    ],
  },
  table: {
    contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
  },
  htmlSupport: {
    allow: [
      {
        name: /.*/,
        attributes: true,
        classes: true,
        styles: true,
      },
    ],
  },
};

const uploadAdapter = (loader) => {
  return {
    upload: async () => {
      try {
        const file = await loader.file;
        if (file) {
          return axios
            .post(
              `${process.env.REACT_APP_HOST}${process.env.REACT_APP_COMMON_BASE_URL}/upload/upload-aws`,
              {
                fileName: file.name,
                fileType: file.type,
              }
            )
            .then(async ({ data: res }) => {
              return awsAxios.put(res.signedRequest, file).then(() => {
                return { default: res.url };
              });
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          return { default: "" };
        }
      } catch (error) {
        return { default: error?.message };
      }
    },
    abort: () => {},
  };
};
const CustomEditor = (props) => {
  return (
    <CKEditor
      editor={ClassicEditor}
      config={editorConfiguration}
      data={props.data}
      className={props.className}
      onChange={props.onChange}
      onReady={(e) => {
        e.plugins.get("FileRepository").createUploadAdapter = (loader) => {
          return uploadAdapter(loader);
        };
      }}
    />
  );
};
export default CustomEditor;
