const supportedChains = [
  {
    name: "bsc-mainnet",
    chain_id: "0x38",
    network_id: 56,
  },
  {
    name: "bsc-testnet",
    chain_id: "0x61",
    network_id: 97,
  },
  {
    name: "ETH-mainnet",
    chain_id: "0x1",
    network_id: 1,
  },
  {
    name: "Goerli-testnet",
    chain_id: "0x5",
    network_id: 5,
  },
  {
    name: "Mumbai-testnet",
    chain_id: "0x13881",
    network_id: 80001,
  }
];

export default supportedChains;
