import axios from "axios";
import { MasterManagementActions } from "./masterManagementAction";
import { showSuccessSnackbar } from "../../snackBar/snackBar.action";

const getAdminURL = (state) => {
  return state.environnment.environmentLists.adminBaseURL;
};

export const getAllMasterDataAsync = () => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());

      dispatch(MasterManagementActions.getAllMasterDataStart());
      const { data } = await axios({
        method: "Get",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/getAllMasterData`,
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (data.responseCode === 200) {
        return dispatch(
          MasterManagementActions.getAllMasterDataSuccess(data.responseData)
        );
      }
      dispatch(MasterManagementActions.getAllMasterDataError());
      return dispatch(
        showSuccessSnackbar("success", data.responseMessage, 3000)
      );
    } catch (error) {
      dispatch(MasterManagementActions.getAllMasterDataError());
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};

export const createMasterValuesAsync = (data1) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());

      dispatch(MasterManagementActions.createMasterDataStart());
      const { data } = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/createMasterData`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data1,
      });
      if (data.responseCode === 200) {
        dispatch(
          MasterManagementActions.createMasterDataSuccess(data.responseData)
        );
        return dispatch(
          showSuccessSnackbar("success", data.responseMessage, 3000)
        );
      }
      dispatch(MasterManagementActions.createMasterDataError());
      return dispatch(showSuccessSnackbar("error", data.responseMessage, 3000));
    } catch (error) {
      dispatch(MasterManagementActions.createMasterDataError(error));
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};

export const addMasterValuesAsync = (data1, id) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());

      dispatch(MasterManagementActions.addMasterValuesStart());
      const { data } = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/addMasterValues/${id.id}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data1,
      });
      if (data.responseCode === 200) {
        dispatch(
          MasterManagementActions.addMasterValuesSuccess(data.responseData)
        );
        return dispatch(
          showSuccessSnackbar("success", data.responseMessage, 3000)
        );
      }
      dispatch(MasterManagementActions.addMasterValuesError());
      return dispatch(showSuccessSnackbar("error", data.responseMessage, 3000));
    } catch (error) {
      dispatch(MasterManagementActions.addMasterValuesError(error));
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};

export const deleteMasterValuesAsync = (masterId, valueId) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());

      dispatch(MasterManagementActions.deleteMasterDataStart());
      const { data } = await axios({
        method: "DELETE",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/deleteMasterData/${masterId}/${valueId}`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (data.responseCode === 200) {
        dispatch(
          MasterManagementActions.deleteMasterDataSuccess(data.responseData)
        );
        return dispatch(
          showSuccessSnackbar("success", data.responseMessage, 3000)
        );
      }
      dispatch(MasterManagementActions.deleteMasterDataError());

      return dispatch(showSuccessSnackbar("error", data.responseMessage, 3000));
    } catch (error) {
      dispatch(MasterManagementActions.deleteMasterDataError(error));
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};
