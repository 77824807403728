export const BlogManagementMap = {
  GET_ALL_BLOG: "GET_ALL_BLOG",
  GET_ALL_BLOG_SUCCESS: "GET_ALL_BLOG_SUCCESS",
  GET_ALL_BLOG_ERROR: "GET_ALL_BLOG_ERROR",
  GET_ALL_BLOG_START: "GET_ALL_BLOG_START",
  SEARCH_TEXT_CHANGE: "SEARCH_TEXT_CHANGE",
  DELETE_BLOG_START: "DELETE_BLOG_START",
  DELETE_BLOG_SUCCESS: "DELETE_BLOG_SUCCESS",
  DELETE_BLOG_ERROR: "DELETE_BLOG_ERROR",
  SELECT_BLOG_DETAILS: "SELECT_BLOG_DETAILS",
  ADD_BLOG_START: "ADD_BLOG_START",
  ADD_BLOG_SUCCESS: "ADD_BLOG_SUCCESS",
  ADD_BLOG_ERROR: "ADD_BLOG_ERROR",
  UPDATE_BLOG_START: "UPDATE_BLOG_START",
  UPDATE_BLOG_SUCCESS: "UPDATE_BLOG_SUCCESS",
  UPDATE_BLOG_ERROR: "UPDATE_BLOG_ERROR",
};

export const BlogManagementActions = {
  getAllBlog: (data) => ({
    type: BlogManagementMap.GET_ALL_BLOG,
    payload: data,
  }),
  getAllBlogSuccess: (data) => ({
    type: BlogManagementMap.GET_ALL_BLOG_SUCCESS,
    payload: data,
  }),
  getAllBlogError: (errors) => ({
    type: BlogManagementMap.GET_ALL_BLOG_ERROR,
    payload: { errors },
  }),
  getAllBlogStart: (data) => ({
    type: BlogManagementMap.GET_ALL_BLOG_START,
    payload: data,
  }),
  searchTextChange: (data) => ({
    type: BlogManagementMap.SEARCH_TEXT_CHANGE,
    payload: data,
  }),
  deleteBlogStart: (data) => ({
    type: BlogManagementMap.DELETE_BLOG_START,
    payload: data,
  }),
  deleteBlogSuccess: (data) => ({
    type: BlogManagementMap.DELETE_BLOG_SUCCESS,
    payload: data,
  }),
  deleteBlogError: (errors) => ({
    type: BlogManagementMap.DELETE_BLOG_ERROR,
    payload: { errors },
  }),
  setSelectedBlog: (data) => ({
    type: BlogManagementMap.SELECT_BLOG_DETAILS,
    payload: data,
  }),
  addBlogStart: (data) => ({
    type: BlogManagementMap.ADD_BLOG_START,
    payload: data,
  }),
  addBlogSuccess: (data) => ({
    type: BlogManagementMap.ADD_BLOG_SUCCESS,
    payload: data,
  }),
  addBlogError: (errors) => ({
    type: BlogManagementMap.ADD_BLOG_ERROR,
    payload: { errors },
  }),
  updateBlogStart: (data) => ({
    type: BlogManagementMap.UPDATE_BLOG_START,
    payload: data,
  }),
  updateBlogSuccess: (data) => ({
    type: BlogManagementMap.UPDATE_BLOG_SUCCESS,
    payload: data,
  }),
  updateBlogError: (errors) => ({
    type: BlogManagementMap.UPDATE_BLOG_ERROR,
    payload: { errors },
  }),
};
