import { BlogManagementMap } from "./blogManagementAction";

const initialState = {
  isLoading: false,
  blogList: {},
  refreshBlogList: true,
  selectedBlog: null,
  search: "",
  page: 0,
  pageSize: 10,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case BlogManagementMap.GET_ALL_BLOG_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case BlogManagementMap.GET_ALL_BLOG: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case BlogManagementMap.GET_ALL_BLOG_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        blogList: action.payload,
        refreshBlogList: false,
      };
    }
    case BlogManagementMap.GET_ALL_BLOG_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshBlogList: false,
      };
    }
    case BlogManagementMap.SEARCH_TEXT_CHANGE: {
      return {
        ...state,
        search: action.payload,
      };
    }
    case BlogManagementMap.DELETE_BLOG_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case BlogManagementMap.DELETE_BLOG_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        refreshBlogList: true,
      };
    }
    case BlogManagementMap.DELETE_BLOG_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshBlogList: false,
      };
    }
    case BlogManagementMap.SELECT_BLOG_DETAILS: {
      return {
        ...state,
        isLoading: false,
        selectedBlog: action.payload,
      };
    }
    case BlogManagementMap.ADD_BLOG_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case BlogManagementMap.ADD_BLOG_SUCCESS: {
      return {
        ...state,
        refreshBlogList: true,
      };
    }
    case BlogManagementMap.ADD_BLOG_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshBlogList: false,
      };
    }
    case BlogManagementMap.UPDATE_BLOG_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case BlogManagementMap.UPDATE_BLOG_SUCCESS: {
      return {
        ...state,
        refreshBlogList: true,
      };
    }
    case BlogManagementMap.UPDATE_BLOG_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshBlogList: false,
      };
    }
    default:
      return { ...state };
  }
};
