/* eslint-disable no-restricted-imports */
import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Button, Modal } from "react-bootstrap";
import { useParams, Redirect, Link } from "react-router-dom";
import { Formik, Form } from "formik";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from "react-select";
import {
  Card,
  CardHeader,
  CardBody,
} from "../../../../_metronic/_partials/controls";
import * as Yup from "yup";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import CustomEditor from "../../CustomEditor/CustomEditor";
import { uniqueId } from "lodash";
import { getAllMasterDataAsync } from "../../masterManagement/redux";
import { addBlogItem, updateBlogItem } from "../redux/blogManagementApi";
import { showSuccessSnackbar } from "../../snackBar/snackBar.action";

export const validateImage = /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i;

const AddBlog = () => {
  const { selectedBlog, templates } = useSelector(
    (state) => state.blogManagement,
    shallowEqual
  );
  const templateOptions = [];
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const formikRef = useRef();
  const categories = [];

  const { masterData } = useSelector(
    (state) => state.masterManagement,
    shallowEqual
  );

  useEffect(() => {
    dispatch(getAllMasterDataAsync());
  }, []);

  const blogCategories = masterData?.find(
    (master) => master.type === "Blog Category"
  );

  // const getBlogCategory =
  const isEdit = selectedBlog && params?.id !== undefined;

  const editedSections = selectedBlog?.sections.map((section) => {
    return {
      ...section,
      subSections: section.subSections.map((sub) => {
        return {
          ...sub,
          parentId: section._id,
        };
      }),
    };
  });

  const [show, setShow] = useState(false);
  const [sections, setSections] = useState(isEdit ? editedSections : []);
  const [deleteModal, setDeleteModal] = useState(false);
  const [file, setFile] = useState({ file: null, url: "" });

  // SECTION TYPE
  // {
  //   parentId: '',
  //   _id: "",
  //   title: "",
  //   description: ''
  //   subSections: []
  // }
  const [sectionSelected, setSectionSelected] = useState();
  const [isNew, setIsNew] = useState(false);

  if (blogCategories && blogCategories?.values) {
    blogCategories.values.forEach((category) => {
      categories.push({ value: category._id, label: category.name });
    });
  }

  if (!selectedBlog && params?.id !== undefined) {
    return <Redirect to="/blog-management" />;
  }

  const initialValues = {
    title: "",
    categories: [],
    coverImage: "",
    description: "",
    estTime: 1,
  };

  const initialSection = {
    title: "",
    sectionParent: "",
  };

  if (templates && templates.length > 0) {
    initialValues.templates = templates;
    templates.forEach((entity) => {
      templateOptions.push({
        value: entity.templateName,
        label: entity.templateName,
      });
    });
  }

  const addBlogSchema = Yup.object().shape({
    title: Yup.string()
      .trim()
      .notOneOf(
        ["null", "NULL", "Null", "Undefined", "undefined"],
        "Please enter blog title"
      )
      .required("Please enter blog title"),
    estTime: Yup.number("Must be a number type").required(
      "Please enter est time"
    ),
    categories: Yup.array().required("Please select at least category"),
    description: Yup.string()
      .trim()
      .notOneOf(
        ["null", "NULL", "Null", "Undefined", "undefined"],
        "Please enter blog description"
      )
      .required("Please enter blog description"),
    coverImage: Yup.string()
      .trim()
      .notOneOf(
        ["null", "NULL", "Null", "Undefined", "undefined"],
        "Please upload cover image"
      )
      .required("Please upload cover image"),
  });

  const addSectionSchema = Yup.object().shape({
    title: Yup.string()
      .trim()
      .notOneOf(
        ["null", "NULL", "Null", "Undefined", "undefined"],
        "Please enter section name"
      )
      .required("Please enter section name")
      .test("Unique", "Section name need to be unique", (values) => {
        const formValues = formikRef.current?.values;
        return !sections.some(
          (section) => section.title === values && !formValues.sectionParent
        );
      }),
  });

  const handleToggleModal = () => {
    setShow(!show);
  };

  const onSaveSection = (values) => {
    // Sub Section
    if (values.sectionParent) {
      if (sectionSelected) {
        if (sectionSelected.parentId === values.sectionParent) {
          const newSections = sections.map((sec) =>
            sec._id === sectionSelected.parentId
              ? {
                  ...sec,
                  subSections: sec.subSections.map((subSec) =>
                    subSec._id === sectionSelected._id
                      ? {
                          ...subSec,
                          title: values.title,
                        }
                      : subSec
                  ),
                }
              : sec
          );
          setSections(newSections);
        } else {
          const newSections = sections.map((sec) =>
            sec._id === sectionSelected.parentId
              ? {
                  ...sec,
                  subSections: sec.subSections.filter(
                    (subSec) => subSec._id !== sectionSelected._id
                  ),
                }
              : sec._id === values.sectionParent
              ? {
                  ...sec,
                  subSections: [
                    ...sec.subSections,
                    {
                      ...sectionSelected,
                      title: values.title,
                      parentId: sec._id,
                    },
                  ],
                }
              : sec
          );
          setSections(newSections);
        }
      }
      // New sub Section
      else {
        const newSubSection = {
          title: values.title,
          subSections: [],
          description: "",
          _id: uniqueId("subSecP_"),
          parentId: values.sectionParent,
        };
        const newSections = sections.map((section) =>
          section._id === values.sectionParent
            ? {
                ...section,
                subSections: [...section.subSections, newSubSection],
              }
            : section
        );
        setSections(newSections);
      }
    }
    // Section
    else {
      // Edit Section
      if (sectionSelected) {
        setSections(
          sections.map((section) =>
            section._id === sectionSelected._id
              ? { ...section, title: values.title }
              : section
          )
        );
      }
      // New Section
      else {
        const newSection = {
          title: values.title,
          subSections: [],
          description: "",
          _id: uniqueId("secP_"),
        };
        setSections([...sections, newSection]);
      }
    }
  };

  const handleShowEdit = (section) => {
    setIsNew(false);
    setSectionSelected(section);
    handleToggleModal();
  };

  const deleteClose = () => setDeleteModal(false);
  const deleteShow = () => setDeleteModal(true);

  const onDeleteClick = (sectionItem) => {
    setSectionSelected(sectionItem);
    deleteShow();
  };

  const onDeactiveClick = () => {
    if (sectionSelected.parentId) {
      const selectedParent = sections.find(
        (item) => item._id === sectionSelected.parentId
      );
      const filteredChild = selectedParent.subSections.filter(
        (val) => val._id !== sectionSelected._id
      );

      setSections((prev) =>
        prev.map((item) =>
          item._id === selectedParent._id
            ? {
                ...item,
                subSections: filteredChild,
              }
            : item
        )
      );
    } else {
      setSections((prev) =>
        prev.filter((val) => val._id !== sectionSelected._id)
      );
    }

    setSectionSelected();
    deleteClose();
  };

  const redirectBack = () => {
    history.push("/blog-management");
  };

  const handleSaveBlog = (values, setSubmitting, resetForm) => {
    const blogBody = {
      ...values,
      categories: values.categories.map((item) => item.value),
      sections: sections.map((sec) =>
        sec.subSections
          ? {
              title: sec.title,
              description: sec.description,
              subSections: sec.subSections.map((sub) => {
                return {
                  title: sub.title,
                  description: sub.description,
                };
              }),
            }
          : {
              title: sec.title,
              description: sec.description,
            }
      ),
    };

    if (isEdit) {
      dispatch(
        updateBlogItem(blogBody, setSubmitting, file, resetForm, redirectBack)
      );
    } else {
      dispatch(
        addBlogItem(blogBody, setSubmitting, file, resetForm, redirectBack)
      );
    }
  };

  const uploadImage = (e, setFieldValue) => {
    if (e.target?.files[0]) {
      const file = e.target?.files[0];
      const isImage = validateImage.test(file.name);
      if (!isImage) {
        showSuccessSnackbar("error", "You can only upload image!", 3000);
        return false;
      }
      if (file.size > 5000000) {
        showSuccessSnackbar("error", "File too big", 3000);
        return false;
      } else {
        const imageUrl = URL.createObjectURL(file);
        setFieldValue("coverImage", imageUrl);
        setFile({
          url: imageUrl,
          file: e.target.files[0],
        });
        return false;
      }
    }
  };

  const editSectionContent = (value) => {
    if (value) {
      const newSections = sections.map((section) => {
        return sectionSelected.parentId &&
          section._id === sectionSelected.parentId
          ? {
              ...section,
              subSections: section.subSections.map((subSection) =>
                subSection._id === sectionSelected._id
                  ? {
                      ...subSection,
                      description: value,
                    }
                  : subSection
              ),
            }
          : section._id === sectionSelected._id
          ? { ...section, description: value }
          : section;
      });
      setSections(newSections);
    }
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={
          isEdit
            ? {
                ...selectedBlog,
                categories: selectedBlog.categories.map((item) => ({
                  value: item._id,
                  label: item.name,
                })),
              }
            : initialValues
        }
        validationSchema={addBlogSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          handleSaveBlog(values, setSubmitting, resetForm);
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          handleBlur,
          handleChange,
          setFieldValue,
          setFieldTouched,
        }) => (
          <Card>
            <CardHeader
              title={
                <div className="form-group m-0 d-flex align-items-center">
                  <Link className="btn btn-link px-0" to="/blog-management">
                    <SVG
                      className="h-50 align-self-center"
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Navigation/Arrow-left.svg"
                      )}
                    />
                    Back
                  </Link>
                  <span className="pl-2">
                    {selectedBlog ? "Edit Blog" : "Add Blog"}
                  </span>
                </div>
              }
            ></CardHeader>
            <CardBody>
              <Row className="justify-content-center">
                <Col lg={4}>
                  <div className="pro_img cover-img">
                    {file.url || selectedBlog?.coverImage ? (
                      <img
                        src={file.url || selectedBlog?.coverImage}
                        alt="blog"
                      />
                    ) : (
                      <div className="upload-wrapper">
                        <span className="svg-icon svg-icon-md svg-icon-danger">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/General/upload.svg"
                            )}
                          />
                        </span>
                        Cover Image
                      </div>
                    )}
                    <input
                      type="file"
                      accept=".png, .jpg, .jpeg"
                      onChange={(e) => uploadImage(e, setFieldValue)}
                    />
                    {file.url || selectedBlog?.coverImage ? (
                      <div className="edt_img_btn">
                        <span className="material-icons">edit</span>EDIT
                      </div>
                    ) : null}
                  </div>
                  {errors.coverImage ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{errors.coverImage}</div>
                    </div>
                  ) : null}
                  <div className="tree-sections">
                    <div className="d-flex align-items-center justify-content-between">
                      <h4 className="mb-0">Blog Section(s)</h4>
                      <Button
                        size="sm"
                        variant="success"
                        className="ml-2"
                        onClick={() => {
                          setIsNew(true);
                          handleToggleModal();
                        }}
                      >
                        + New Section
                      </Button>
                    </div>
                    <div className="tree-list mt-4">
                      {sections?.map((item, index) => (
                        <div key={index}>
                          <div className="hover-item d-flex align-items-center justify-content-between">
                            <div
                              className="section-title"
                              onClick={() => setSectionSelected(item)}
                              style={
                                sectionSelected?._id === item._id
                                  ? {
                                      fontWeight: "600",
                                      background:
                                        "linear-gradient(90deg, rgba(200,216,244,0.96) 0%, rgba(200,216,244,0.5) 50%, rgba(200,216,244,0) 100%)",
                                      width: "calc(100% - 40px)",
                                    }
                                  : {}
                              }
                            >
                              {item.title}
                            </div>
                            <div className="d-flex align-items-center">
                              <button
                                onClick={() => onDeleteClick(item)}
                                className="edit-section-btn btn-small"
                              >
                                <span className="svg-icon svg-icon-md svg-icon-danger">
                                  <SVG
                                    src={toAbsoluteUrl(
                                      "/media/svg/icons/General/Trash.svg"
                                    )}
                                  />
                                </span>
                              </button>
                              <button
                                onClick={() => handleShowEdit(item)}
                                className="edit-section-btn btn-small"
                              >
                                <span className="svg-icon svg-icon-md svg-icon-primary">
                                  <SVG
                                    src={toAbsoluteUrl(
                                      "/media/svg/icons/Communication/Write.svg"
                                    )}
                                  />
                                </span>
                              </button>
                            </div>
                          </div>
                          {item.subSections?.length
                            ? item.subSections.map((subItem, i) => (
                                <div
                                  className="hover-item d-flex align-items-center justify-content-between"
                                  key={i}
                                >
                                  <div
                                    className="sub-section-title"
                                    onClick={() => setSectionSelected(subItem)}
                                    style={
                                      sectionSelected?._id === subItem._id
                                        ? {
                                            fontWeight: "600",
                                            background:
                                              "linear-gradient(90deg, rgba(200,216,244,0.96) 0%, rgba(200,216,244,0.5) 50%, rgba(200,216,244,0) 100%)",
                                            width: "calc(100% - 40px)",
                                          }
                                        : {}
                                    }
                                  >
                                    {subItem.title}
                                  </div>
                                  <div className="d-flex align-items-center">
                                    <button
                                      onClick={() => onDeleteClick(subItem)}
                                      className="edit-section-btn btn-small"
                                    >
                                      <span className="svg-icon svg-icon-md svg-icon-danger">
                                        <SVG
                                          src={toAbsoluteUrl(
                                            "/media/svg/icons/General/Trash.svg"
                                          )}
                                        />
                                      </span>
                                    </button>
                                    <button
                                      onClick={() => handleShowEdit(subItem)}
                                      className="edit-section-btn btn-small"
                                    >
                                      <span className="svg-icon svg-icon-md svg-icon-primary">
                                        <SVG
                                          src={toAbsoluteUrl(
                                            "/media/svg/icons/Communication/Write.svg"
                                          )}
                                        />
                                      </span>
                                    </button>
                                  </div>
                                </div>
                              ))
                            : null}
                        </div>
                      ))}
                    </div>
                  </div>
                </Col>
                <Col lg={8}>
                  <Form>
                    <div className="form-group">
                      <label className="form-label">Title</label>
                      <input
                        placeholder="Enter Title"
                        type="text"
                        name="title"
                        className={
                          errors.title && touched.title
                            ? "form-control re_inputRouded is-invalid"
                            : "form-control re_inputRouded"
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.title}
                      />
                      {touched.title && errors.title ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.title}</div>
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <label className="form-label">EST Time</label>
                      <input
                        placeholder="Enter est time"
                        type="number"
                        name="estTime"
                        className={
                          errors.estTime && touched.estTime
                            ? "form-control re_inputRouded is-invalid"
                            : "form-control re_inputRouded"
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.estTime}
                      />
                      {touched.estTime && errors.estTime ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">{errors.estTime}</div>
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <label className="pho_14_500">Tags</label>
                      <Select
                        value={values.categories}
                        name="categories"
                        onBlur={() => setFieldTouched("categories", true)}
                        onChange={(value) => {
                          // Set field value
                          setFieldValue("categories", value);
                        }}
                        isMulti
                        placeholder="Select"
                        className="basic-multi-select form-control border-0 p-0"
                        classNamePrefix="select"
                        options={categories}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: "#DCF4E4",
                            primary75: "#DCF4E4",
                            primary50: "#DCF4E4",
                            primary: "#50C878",
                          },
                        })}
                      />
                      {touched.categories && errors.categories ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {errors.categories}
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <label className="form-label">Blog description</label>

                      <CustomEditor
                        data={values.description}
                        className={
                          errors.description && touched.description
                            ? "form-control re_inputRouded is-invalid"
                            : "form-control re_inputRouded"
                        }
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          // Set field touched value
                          setFieldTouched("description", true);

                          // Set field value
                          setFieldValue("description", data);
                        }}
                      />

                      {touched.description && errors.description ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {errors.description}
                          </div>
                        </div>
                      ) : null}
                    </div>
                    {sectionSelected && (
                      <div
                        className="section-container"
                        key={sectionSelected?._id}
                      >
                        <div className="divider" />
                        <div>
                          <div className="form-group">
                            <label className="form-label">
                              {sectionSelected.title} - Section content
                            </label>
                            <CustomEditor
                              data={sectionSelected.description}
                              // className={
                              //   errors.sectionContent && touched.sectionContent
                              //     ? "form-control re_inputRouded is-invalid"
                              //     : "form-control re_inputRouded"
                              // }
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                editSectionContent(data);
                                // Set field touched value
                                // setFieldTouched("sectionContent", true);
                              }}
                            />

                            {/* {touched.sectionContent && errors.sectionContent ? (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                  {errors.sectionContent}
                                </div>
                              </div>
                            ) : null} */}
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="form-group d-flex flex-wrap justify-content-center align-items-center mb-0 mt-4">
                      <div className="mr-5 mb-1 mt-2">
                        <Link
                          className="btn btn-outline-blue"
                          to="/blog-management"
                        >
                          Close
                        </Link>
                      </div>
                      <div className="mb-1 mt-2">
                        <Button
                          variant="blue"
                          className="px-5 defpddng spinnerBtn"
                          size="lg"
                          type="submit"
                        >
                          {isEdit ? "Update" : "Submit"}
                          {isSubmitting && (
                            <div className="ml-3 basic-verification-loader text-center">
                              <CircularProgress />
                            </div>
                          )}
                        </Button>
                      </div>
                    </div>
                  </Form>
                </Col>
              </Row>
            </CardBody>
          </Card>
        )}
      </Formik>
      <Modal
        show={show}
        onHide={() => {
          handleToggleModal();
          setSectionSelected();
        }}
        className="def_modal delete_modal"
      >
        <Modal.Header>
          <Modal.Title className="fn_s_18 color_blk f-w-700">
            Add New Section
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            enableReinitialize
            initialValues={
              isNew
                ? initialSection
                : sectionSelected
                ? sectionSelected
                : initialSection
            }
            validationSchema={addSectionSchema}
            onSubmit={(values, { resetForm }) => {
              onSaveSection(values);
              resetForm();
              setSectionSelected();
              handleToggleModal();
            }}
            innerRef={formikRef}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              setFieldValue,
            }) => (
              <Form>
                <div className="form-group">
                  <label className="form-label">Section name</label>
                  <input
                    placeholder="Enter name"
                    type="text"
                    name="title"
                    className={
                      errors.title && touched.title
                        ? "form-control re_inputRouded is-invalid"
                        : "form-control re_inputRouded"
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.title}
                  />
                  {touched.title && errors.title ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{errors.title}</div>
                    </div>
                  ) : null}
                </div>
                <div className="form-group">
                  <label className="pho_14_500">Parent section</label>
                  <Select
                    value={{
                      value: values.sectionParent,
                      label: sections.find(
                        (sec) =>
                          sec._id ===
                          (values.sectionParent || sectionSelected?.parentId)
                      )?.title,
                    }}
                    onChange={(e) => {
                      setFieldValue("sectionParent", e.value);
                    }}
                    placeholder="Select"
                    className={`form-control border-0 p-0 `}
                    classNamePrefix="phoSelect"
                    options={sections
                      .filter((section) => section._id !== sectionSelected?._id)
                      .map((item) => {
                        return {
                          value: item._id,
                          label: item.title,
                        };
                      })}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: "#DCF4E4",
                        primary75: "#DCF4E4",
                        primary50: "#DCF4E4",
                        primary: "#50C878",
                      },
                    })}
                  />
                </div>
                <div className="form-group d-flex flex-wrap justify-content-center align-items-center mb-0">
                  <Button
                    type="button"
                    className="btn btn-def btn_pdng mr-3"
                    onClick={handleToggleModal}
                  >
                    Cancel
                  </Button>
                  <Button className="btn btn-delete btn_pdng" type="submit">
                    Save
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
      <Modal
        show={deleteModal}
        onHide={deleteClose}
        centered
        className="def_modal delete_modal"
      >
        <Modal.Header>
          <Modal.Title className="fn_s_18 color_blk f-w-700">Alert</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="form def_form frmwtpddng">
            <p className="fn_s_18 f-w-400 text-center mb-10">
              Are you sure want you to delete this section?
            </p>
            <div className="form-group d-flex flex-wrap justify-content-center align-items-center mb-0">
              <button
                type="button"
                className="btn btn-def btn_pdng mr-3"
                onClick={deleteClose}
              >
                Keep
              </button>
              <button
                type="button"
                className="btn btn-delete btn_pdng"
                onClick={onDeactiveClick}
              >
                Yes, delete it
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default AddBlog;
