import axios from "axios";
import { BlogManagementActions } from "./blogManagementAction";
import { showSuccessSnackbar } from "../../snackBar/snackBar.action";

const awsAxios = axios.create();

const getAdminURL = (state) => {
  return state.environnment.environmentLists.adminBaseURL;
};

const getCommonBaseURL = (state) => {
  return state.environnment.environmentLists.commonBaseURL;
};

export const getAllBlog = (search) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());

      let { page, pageSize } = getState().blogManagement;
      dispatch(BlogManagementActions.getAllBlogStart());
      const { data } = await axios({
        method: "Get",
        url: `${process.env.REACT_APP_HOST}${adminBaseURL}/blogs?page=${page}&pageSize=${pageSize}&search=${search}`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (data.responseCode === 200) {
        return dispatch(
          BlogManagementActions.getAllBlogSuccess(data.responseData)
        );
      }
      dispatch(BlogManagementActions.getAllBlogError());
      return dispatch(
        showSuccessSnackbar("success", data.responseMessage, 3000)
      );
    } catch (error) {
      dispatch(BlogManagementActions.getAllBlogError());
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};

export const deleteBlog = (id) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());

      dispatch(BlogManagementActions.deleteBlogStart());
      const { data } = await axios.delete(
        `${process.env.REACT_APP_HOST}${adminBaseURL}/blogs/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (data.responseCode === 200) {
        dispatch(BlogManagementActions.deleteBlogSuccess(data.responseData));
        return dispatch(
          showSuccessSnackbar("success", data.responseMessage, 3000)
        );
      }
      dispatch(BlogManagementActions.deleteBlogError());

      return dispatch(showSuccessSnackbar("error", data.responseMessage, 3000));
    } catch (error) {
      dispatch(BlogManagementActions.deleteBlogError(error));
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};

export const addBlogItem = (
  values,
  setSubmitting,
  file,
  resetForm,
  redirectBack
) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());
      const commonBaseURL = getCommonBaseURL(getState());
      dispatch(BlogManagementActions.addBlogStart());
      if (file) {
        const fileTag = file.file.name.split(".").pop();

        const { data } = await axios({
          method: "POST",
          url: `${process.env.REACT_APP_HOST}${commonBaseURL}/upload/upload-aws`,
          data: {
            fileName: file.file.name,
            fileType: `image/${fileTag}`,
          },
        });

        await awsAxios.put(data?.signedRequest, file.file);
        const { data: blogData } = await axios({
          method: "POST",
          url: `${process.env.REACT_APP_HOST}${adminBaseURL}/blogs`,
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            ...values,
            coverImage: data.url,
          },
        });
        if (blogData.responseCode === 200) {
          dispatch(BlogManagementActions.addBlogSuccess(blogData.responseData));
          setSubmitting(false);
          resetForm();
          redirectBack();
          return dispatch(
            showSuccessSnackbar("success", blogData.responseMessage, 3000)
          );
        }
        dispatch(BlogManagementActions.addBlogError());
        setSubmitting(false);
        return dispatch(
          showSuccessSnackbar("error", blogData.responseMessage, 3000)
        );
      }
    } catch (error) {
      dispatch(BlogManagementActions.addBlogError(error));
      setSubmitting(false);
      resetForm();
      dispatch(showSuccessSnackbar("error", "Error while fetching data", 3000));
    }
  };
};

export const updateBlogItem = (
  values,
  setSubmitting,
  file,
  resetForm,
  redirectBack
) => {
  return async (dispatch, getState) => {
    try {
      const adminBaseURL = getAdminURL(getState());
      const commonBaseURL = getCommonBaseURL(getState());
      let imgData;

      dispatch(BlogManagementActions.updateBlogStart());

      if (file.file !== null) {
        const fileTag = file.file.name.split(".").pop();
        const { data } = await axios.post(
          `${process.env.REACT_APP_HOST}${commonBaseURL}/upload/upload-aws`,
          {
            fileName: file.file.name,
            fileType: `image/${fileTag}`,
          }
        );

        await awsAxios.put(data?.signedRequest, file.file);
        imgData = data;
      }

      const { data: blogData } = await axios.put(
        `${process.env.REACT_APP_HOST}${adminBaseURL}/blogs/${values._id}`,
        {
          ...values,
          coverImage: imgData?.url || values.coverImage,
        }
      );

      if (blogData.responseCode === 200) {
        dispatch(
          BlogManagementActions.updateBlogSuccess(blogData.responseData)
        );
        setSubmitting(false);
        resetForm();
        redirectBack();
        return dispatch(
          showSuccessSnackbar("success", blogData.responseMessage, 3000)
        );
      } else {
        dispatch(BlogManagementActions.updateBlogError());
        setSubmitting(false);
        return dispatch(
          showSuccessSnackbar("error", blogData.responseMessage, 3000)
        );
      }
    } catch (error) {
      dispatch(BlogManagementActions.updateBlogError(error));
      setSubmitting(false);
      resetForm();
      dispatch(
        showSuccessSnackbar(
          "error",
          "Error while updating blog. Please try again later",
          3000
        )
      );
    }
  };
};
