import React from "react";
import { Table } from "react-bootstrap";
import { UserManagementActions } from "../../redux";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import BasicPagination from "../../../pagination/BasicPagination";
import CircularProgress from "@material-ui/core/CircularProgress";

const Referral = () => {
  const {
    selectedUser,
    userReferralSkip,
    userReferralLimit,
    isLoading,
  } = useSelector((state) => state.userManagement, shallowEqual);
  const dispatch = useDispatch();

  const onPageChange = (currentBatch) => {
    let count = currentBatch ? currentBatch - 1 : userReferralSkip;
    dispatch(UserManagementActions.setUserReferralNumber(count));
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="ovflhddn">
            <div className="tblmrgn mt-0">
              <Table
                hover
                responsive
                className="mb-0 default_table with_hoverbtn mrpdng_tbl"
              >
                <thead>
                  <tr>
                    <th className="wd-120">Sr.no</th>
                    <th>Full Name</th>
                    <th>Email</th>
                    <th className="wd-120">Register At</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedUser &&
                  selectedUser.myReferralUsers &&
                  selectedUser.myReferralUsers.length ? (
                    selectedUser.myReferralUsers
                      .slice(
                        userReferralSkip * userReferralLimit,
                        userReferralLimit * (userReferralSkip + 1)
                      )
                      .map((user, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <span className="f-w-500">
                                {userReferralSkip * userReferralLimit +
                                  (index + 1)}
                              </span>
                            </td>
                            <td>{user?.fullName}</td>
                            <td>{user?.emailId}</td>
                            <td>
                              <span className="fn_s_14">
                                {new Date(user?.registerAt).toLocaleString()}
                              </span>
                            </td>
                          </tr>
                        );
                      })
                  ) : (
                    <tr>
                      <td colSpan={8}>No Record Found</td>
                    </tr>
                  )}
                  {isLoading && (
                    <tr>
                      <td colSpan={8} className="text-center p-0 border-0">
                        <div className="basic-verification-loader text-center">
                          <CircularProgress />
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
            {!isLoading && (
              <div className="row d-flex align-items-center mr-5 ml-5 mt-4">
                <div className="col-md-8 text-right mb-5 aspgntn">
                  <BasicPagination
                    totalRecords={selectedUser?.myReferralUsers.length}
                    limit={userReferralLimit}
                    batch={userReferralSkip + 1}
                    onBatchChange={onPageChange}
                  />
                </div>
                <div className="col-md-4 mb-5 fn_s_16 f-w-400 text-right">
                  Displaying {userReferralSkip * userReferralLimit + 1} -{" "}
                  {userReferralSkip * userReferralLimit + userReferralLimit} of{" "}
                  {selectedUser?.myReferralUsers.length} Records
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Referral;
