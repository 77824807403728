import { WtRequestsMap } from "./wtRequestsAction";

const initialState = {
  isLoading: false,
  wtRequestsList: [],
  refreshRequestList: true,
  searchBy: "",
  searchText: "",
  searchStatus: "",
  skip: 0,
  column: "",
  dir: "",
  limit: 10,
  approveResponse: {},
  approveRequestBtn: false,
  approvePaymentLoading: false,
  declinePaymentLoading: false
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case WtRequestsMap.GET_ALL_WT_REQUESTS_START: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case WtRequestsMap.GET_ALL_WT_REQUESTS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        wtRequestsList: action.payload,
        refreshRequestList: false,
      };
    }
    case WtRequestsMap.GET_ALL_WT_REQUESTS_ERROR: {
      return {
        ...state,
        isLoading: false,
        refreshRequestList: false,
      };
    }
    case WtRequestsMap.SEARCH_TEXT_CHANGE: {
      return {
        ...state,
        searchText: action.payload,
      };
    }
    case WtRequestsMap.REFRESH_WT_REQUESTS_LIST: {
      return {
        ...state,
        skip: 0,
        refreshRequestList: true,
      };
    }
    case WtRequestsMap.SET_REQUESTS_BATCH_NUMBER: {
      return {
        ...state,
        skip: action.payload,
        refreshRequestList: true,
      };
    }
    case WtRequestsMap.APPROVE_REQUEST_START: {
      return {
        ...state,
        approveRequestBtn: true,
      };
    }
    case WtRequestsMap.APPROVE_REQUEST_SUCCESS: {
      return {
        ...state,
        approveRequestBtn: false,
        //approveResponse: action.payload
      };
    }
    case WtRequestsMap.APPROVE_REQUEST_ERROR: {
      return {
        ...state,
        approveRequestBtn: false
      };
    }
    case WtRequestsMap.SET_SORT_CHANGE: {
      return {
        ...state,
        searchBy: action.payload?.column,
        dir: action.payload?.dir,
        refreshRequestList: true,
      };
    }
    case WtRequestsMap.APPROVE_PROJECT_START: {
      return {
        ...state,
        approvePaymentLoading: true,
      };
    }
    case WtRequestsMap.APPROVE_PROJECT_SUCCESS: {
      return {
        ...state,
        approvePaymentLoading: false
      };
    }
    case WtRequestsMap.APPROVE_PROJECT_ERROR: {
      return {
        ...state,
        approvePaymentLoading: false
      };
    }
    case WtRequestsMap.DECLINE_PROJECT_START: {
      return {
        ...state,
        declinePaymentLoading: true,
      };
    }
    case WtRequestsMap.DECLINE_PROJECT_SUCCESS: {
      return {
        ...state,
        declinePaymentLoading: false,
      };
    }
    case WtRequestsMap.DECLINE_PROJECT_ERROR: {
      return {
        ...state,
        declinePaymentLoading: false,
      };
    }
    default:
      return { ...state };
  }
};
