import React, { useEffect, useRef } from "react";
import "bootstrap-daterangepicker/daterangepicker.css";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { BlogManagementActions, getAllBlog } from "../redux";
import BlogManagementTable from "./blogManagementTable";
import {
  Card,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";

export const BlogManagement = () => {
  const dispatch = useDispatch();
  const searchEmailRef = useRef();
  const history = useHistory();
  const { refreshBlogList, search } = useSelector(
    (state) => state.blogManagement,
    shallowEqual
  );
  useEffect(() => {
    // dispatch(BlogManagementActions.setSelectedEmail(null));
    // if (searchEmailRef.current) {
    //   let keyPressEvent = (e) => {
    //     if (e.keyCode === 13) {
    //       e.preventDefault();
    //       // dispatch(BlogManagementActions.refreshEmailList());
    //     }
    //   };
    //   let input = searchEmailRef.current;
    //   input.addEventListener("keyup", keyPressEvent);
    //   return () => {
    //     input.removeEventListener("keyup", keyPressEvent);
    //     dispatch(BlogManagementActions.searchTextChange(""));
    //     input.value = "";
    //   };
    // }
  }, []);
  useEffect(() => {
    if (refreshBlogList) {
      dispatch(getAllBlog(search));
    }
  }, [refreshBlogList]);

  const onAddClick = () => {
    console.log("haha");
    dispatch(BlogManagementActions.setSelectedBlog(null));
    history.push("/blog-management/add-new-blog");
  };

  const onSearchTextChange = (e) => {
    dispatch(BlogManagementActions.searchTextChange(e.target.value));
  };
  return (
    <>
      <Card>
        <CardHeader title="Blog Management">
          <CardHeaderToolbar>
            <div className="d-flex flex-wrap ap_filter_wraper justify-content-end align-items-center">
              <form
                className="subheader_filter"
                onSubmit={(e) => e.preventDefault()}
              >
                <div className="form-row">
                  <div className="form-group mr-5 mb-1 mt-2">
                    <button
                      className="btn_new btn-sm"
                      type="button"
                      onClick={() => onAddClick()}
                    >
                      Add
                    </button>
                  </div>
                  <div className="form-group mr-3 mb-1">
                    <input
                      name="Search"
                      placeholder="Search. . ."
                      type="text"
                      className="form-control"
                      value={search}
                      onChange={onSearchTextChange}
                      ref={searchEmailRef}
                    />
                  </div>
                </div>
              </form>
            </div>
          </CardHeaderToolbar>
        </CardHeader>
        <BlogManagementTable />
      </Card>
    </>
  );
};
